@import '../../../styles/general/variables';

.skeletonWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 100px;
    @media screen and (max-width: 768px) {
        display: block;
        > div:first-of-type {
            display: none;
        }
    }
}
