.time {
    position: absolute;
    display: inline-block;
    padding: 4px;
    color: white;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 2px;
    font-size: 10px;
    line-height: 12px;
    z-index: 4;

    & > span {
        display: inline-block;
        vertical-align: center;
    }

    svg {
        position: relative;
        top: 1px;
        margin: 0 5px 0 2px;
    }
}

.list {
    left: 5px;
    bottom: 5px;
}

.detail {
    right: 20px;
    bottom: 20px;
}
