@import '../general/variables';

.inputLarge {
    display: block;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    padding: 15px 20px;
    box-shadow: none;
    margin-bottom: 10px;
}

.fullPageFormArea {
    display: block;
    width: 100%;
    margin: 0 auto 20px;
    max-width: 470px;
}

.errorIcon {
    display: none;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    svg {
        height: 12px;
        width: auto;
        display: block;
    }
}

.inputLabel {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
    font-size: $font-size-small;
    user-select: none;
    &:last-of-type {
        margin-bottom: 15px;
    }
}

.inputLabelInner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;
}

.inputIconWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    &.error {
        .inputLarge {
            border: 1px solid $color-error;
        }
        .errorIcon {
            display: flex;
        }
    }
    //space for icon
    .inputLarge {
        padding-left: 45px;
        margin-bottom: 0;
        font-size: $font-size-small;
    }
    &.button > * {
        cursor: pointer;
    }
}

.inputIcon {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: auto;
        display: block;
    }
}

.inputIconRight {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    svg {
        height: auto;
        display: block;
    }
}

.resendIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-small;
    color: $color-font-grey;
    svg {
        height: auto;
        display: block;
    }
    .link {
        text-decoration: underline;
        color: #000;
        cursor: pointer;
    }
    button {
        padding-right: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.formFooter {
    font-size: $font-size-small;
    color: $color-font-grey;
    margin-top: 25px;
}

.devider {
    border-bottom: 1px solid $color-border-light;
    width: 100%;
    margin-bottom: 20px;
}

.fieldsWrapper {
    margin-bottom: 0px;
}
