@import '../../../styles/general/variables.scss';

.collectionPreview {
    transition: all .2s linear;
    min-width: 100%;
    padding-bottom: 0 !important;

    h3, a {
        text-decoration: none;
    }

    &:hover {
        h3 {
            text-decoration: underline;
        }
    }

    button {
        width: 100%;
        text-align: left;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }

    &.noPadding {
        &:hover {
            filter: brightness(0.7);
        }

        button {
            padding: 0;
        }
    }
}

.imagesHolder {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.mainImage {
    position: relative;
    grid-column: 1/3;
    grid-row: 1;
}

.subImages {
    grid-column: 3/3;
    grid-row: 1;
    padding-left: 5px;

    & > div:not(:last-of-type) {
        margin-bottom: 5px;
    }
}

.imageBox {
    background-color: $color-bg-grey;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #F8F8F8;

    & > figure img, span, svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & > figure img {
        width: 100%;
        height: auto;
    }
}

.aspectRation {
    position: relative;
    height: 0;
    padding-top: 72px / 100px * 100%;
}

.content {
    padding-top: 12px;
}

.title, .mockupsCount {
    font-size: $font-size-small;
    line-height: $line-height-larger;
}

.title {
    display: inline-block;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mockupsCount {
    color: $color-font-grey;
    float: right;
}

.overlayWrap {
    span, svg {
        z-index: 1;
    }

    span {
        color: white;
        font-size: $font-size-small;
    }
}

.overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    transition: all .2s linear;
}

.collected {
    .overlayWrap {

        .overlay {
            background-color: rgba(0, 186, 255, 0.5);
            mix-blend-mode: multiply;
        }

        svg[name="Minus"] {
            opacity: 0;
        }
    }

    &:hover {
        .overlayWrap {
            svg[name="Check"] {
                opacity: 0;
            }

            svg[name="Minus"] {
                opacity: 1;
            }
        }
    }
}

.collect {
    .overlayWrap {
        opacity: 0;

        &.active {
            opacity: 1;

            .overlay {
                background-color: rgba(0, 186, 255, 0.5);
                mix-blend-mode: multiply;
            }

            svg[name="Minus"] {
                opacity: 0;
            }
        }
    }

    &:hover {
        .overlayWrap {
            opacity: 1;
        }
    }
}

.full {
    button {
        cursor: not-allowed;
    }

    .overlay {
        background-color: rgba(0, 186, 255, 0.5);
        mix-blend-mode: multiply;
    }
}



