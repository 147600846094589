@import '../../../styles/general/variables';

.searchContainer {
    height: 50px;
    position: relative;
    z-index: 9;
    &.open {
        .wrapper {
            box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2);
            border-radius: 4px;
            overflow: hidden;
            input {
                 border-color: transparent;
            }
            .selectOptions {
                border-color: transparent;
            }
        }
    }
}
.wrapper {
    position: absolute;
    width: 100%;
}

.icon {
    position: absolute;
    left: 15px;
    top: 18px;
    svg {
        display: block;
    }
    svg,
    path,
    g {
        transition: all 100ms ease-in-out;
    }
}

.close {
    position: absolute;
    right: 15px;
    top: 19px;
    svg {
        display: block;
    }
}

.input {
    font-size: $font-size-small;
    color: $color-font-main;
    display: block;
    width: 100%;
    height: 50px;
    border: 1px solid $color-border;
    border-radius: 4px;
    padding-left: 45px;
    z-index: 999;
    &::placeholder {
        color: lighten($color-font-grey,15);
    }
    ::-webkit-input-placeholder {
        /* Chrome */
        color: lighten($color-font-grey,15);
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: lighten($color-font-grey,15);
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: lighten($color-font-grey,15);
        opacity: 1;
    }
    :-moz-placeholder {
        /* Firefox 4 - 18 */
        color: lighten($color-font-grey,15);
        opacity: 1;
    }
    &:focus + * {
        svg,
        path,
        g {
            fill: #000;
        }
    }
}

.selector {
    position: relative;
}

.selectOptions {
    position: relative;
    width: 100%;
    z-index: 9;
    border: 1px solid $color-border;
    border-top: 0;
    margin-top: -2px;
    background: #fff;
    padding: 0px 40px 30px 40px;
    border-radius: 0 0 4px 4px;
    font-size: $font-size-small;
    .selectOption a.selected,
    .searchOption.selected {
		text-decoration: underline;
    }
    .selectOption {
        padding: 7px 4px;
        em {
            font-style: normal;
            font-weight: 700;
        }
        a {
            text-decoration: none;
        }
    }
    .searchOption {
        @extend .selectOption;
        padding: 17px 4px 0 4px;
        cursor: pointer;
        text-decoration: none;
    }

    .notResults {
        cursor: default;
    }
}

.divider {
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px solid $color-border;
    margin-bottom: 10px
}
