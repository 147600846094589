@import '../../../styles/general/variables';


.uploadButton {
    border: 1px solid $color-border-light;
	border-radius: 3px;
	width: 60px;
    height: 60px;
	position: relative;
	overflow: hidden;
	background-color: #EEE;
}
.uploadButton.darkened > img,
.uploadButton:hover > img {
	opacity: 0.5;
}
.uploadButton::before,
.uploadButton > img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.uploadIcon {
	display: none;
}
.uploadButton:hover .uploadIcon {
	display: unset;
}
.uploadButton > img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.uploadInput {
	display: none;
}

.centeredContent {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	width: fit-content;
}

.predefined {
    &:hover {
        box-shadow: 0 0 0 2px $color-font-violet;
    }
}

.predefinedActive {
    box-shadow: 0 0 0 2px $color-font-violet !important;
    cursor: default;
    pointer-events: none;
}
