@import '../../../styles/general/variables';

.wrapper {
    & > * {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 75%;
            pointer-events: none;
        }
    }

    & > article {
        padding-bottom: 75%;
    }
}

.wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: $space-sm;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $screen-sm) {
    }

    & > * {
        width: 25%;
        padding: $space-xs;
        @media (max-width: $screen-lg) {
            width: 33.3333%;
        }
        @media (max-width: $screen-sm) {
            width: 50%;
            margin: 4px;
        }
    }
}

@supports (display: grid) {
    .wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 18px;
        width: 100%;
        margin: $space-sm auto;

        @media (max-width: $screen-lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $screen-sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 8px;
        }

        & > * {
            width: 100%;
            padding: 0;
            @media (max-width: $screen-lg) {
                width: 100%;
            }
            @media (max-width: $screen-sm) {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.listWrapper {
    margin-top: 18px;
}

.myMasonryGridColumn > *, .wrapperMasonry > * {
    position: relative;
    background-color: $color-bg-grey;

    & > i {
        display: block;
    }

    & > article {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.wrapperMasonry {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-left: -18px;

    & > * {
        margin-left: 18px;
        margin-bottom: 18px;
    }
}

.myMasonryGrid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -8px; /* gutter size offset */
    width: auto;
}
.myMasonryGridColumn {
    padding-left: 8px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.myMasonryGridColumn > * { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 8px;
}
