@import '../../../styles/general/variables';

$homepage-header-padding: 15px;
$homepage-header-padding-sm: 10px;

.topWrapper {
    padding-top: 25px;
    width: 100%;
    overflow: hidden;
}

.topWrapper, .bottomWrapper {
    @media (max-width: $screen-xl) {
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

.row {
    position: relative;

    //need media query
    white-space: nowrap;
}

.content {
    display: inline-block;
    width: 45vw;
    max-width: 610px;
    padding: 0 10px 0 0;
    align-items: center;
    white-space: normal;

    //need media query
    vertical-align: middle;

    @media (max-width: 1019px) {
        display: block;
        width: 100%;
    }

    @media (max-width: 450px) {
        padding: 0;
        margin-bottom: 20px;
    }

    @media (min-width: 451px) and (max-width: $screen-sm) {
        padding: 0;
        margin-bottom: 60px;
    }
}

.title {
    font-size: $font-size-jumbo;
    margin-bottom: 60px;
    @media (max-width: $screen-lg) {
        font-size: 2.4rem;
    }
    @media (max-width: $screen-md) {
        font-size: 2rem;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.8rem;
    }
    @media (max-width: $screen-xsm) {
        font-size: 1.5rem;
    }
    @media (max-width: 450px) {
        margin-bottom: 0;
    }
}

.titleSmaller {
    font-size: 2.8rem;
    margin-bottom: 60px;
    @media (max-width: $screen-lg) {
        font-size: 2.2rem;
    }
}

.titleSmallest {
    font-size: 2.2rem;
    margin-bottom: 60px;
    @media (max-width: $screen-lg) {
        font-size: 2rem;
    }
    @media (max-width: $screen-md) {
        font-size: 1.2rem;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.2rem;
    }
    @media (max-width: $screen-xsm) {
        font-size: 1rem;
    }
}

.searchTitle {
    font-size: $font-size-normal;
    margin-bottom: 0;
}

.mockupsWrapper {
    //need media query
    position: relative;
    display: inline-block;
    width: 50.8vw;
    vertical-align: middle;
    max-width: 1180px;

    @media (max-width: $screen-xl) {
        width: 51.5vw;
    }


    @media (max-width: 1019px) {
        display: block;
        width: 100%;
        margin-left: 0;
    }
}

.mockupsFirstRow {
    position: relative;

    & > div {
        justify-content: flex-end;
    }

    @media (max-width: 1019px) {
        width: 100%;
    }
}

.mockupsSecondRow {
    position: relative;

    & > div {
        justify-content: flex-end;
    }

    @media (max-width: 869px) {
        display: none;
    }
}

.mockupsEmptyTopRow, .mockupsEmptyBottomRow {
    position: relative;
    height: 100px;
    width: 100%;
    overflow-y: hidden;

    @media (max-width: 1019px) {
        display: none;
    }

    & > div {
        grid-template-columns: repeat(3, 1fr);
        position: absolute;
        margin-top: 0;

        & > div > span {
            animation: inherit;
            background: white;
            border: 1px solid $color-border-light;
        }
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.mockupsEmptyTopRow {
    left: 30%;

    &:before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    }

    & > div {
        bottom: 0;
    }
}

.mockupsEmptyBottomRow {
    left: 20%;
    &:before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    & > div {
        top: 0;
    }
}

.bottomWrapper {
    position: relative;
    width: 100%;
}

.searchWrap {
    position: relative;
    margin-top: 15px;
    width: 40%;
}

.filtersWrap {
    position: absolute;
    right: 0;
    width: calc(56% - 15px);
    @media (min-width: 2300px) {
        width: calc(53% - 15px);
    }

    @media (max-width: 869px) {
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

.searchWrap, .filtersWrap {
    display: inline-block;
    vertical-align: top;

    @media (max-width: 869px) {
        display: block;
        width: 100%;
    }
}
