@import '../../../styles/general/variables';

.wrapper {

    .previewContent, .previewContent button, .ratioContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    //conditional hover on mouse devices
    @media (hover: hover) {
        &:hover {
            .collectionBtn {
                opacity: 1;
            }

            .adminBtnWrapper {
                opacity: 1;
            }

            .listTitles {
                opacity: 1;
            }

            .listTitlesInverse {
                opacity: 1;
            }

            .previewTitle {
                opacity: 1;
            }

            .hover,
            .hoverBordered {
                opacity: 1;
                visibility: visible;
            }

            .previewTitleInverse {
                opacity: 1;
            }

            .videoBadges {
                opacity: 0;
            }
        }
    }
    .previewContent {
        height: 100%;
        button {
            width: 100%;
            position: relative;
            padding: 0;
            display: block;
            cursor: pointer;
            height: 100%;
        }
    }
}

.mockupPreview {
    cursor: pointer;
    display: block;
    position: relative;
    background-color: #f5f5f5;
    overflow: hidden;
    border-radius: 4px;
    height: 100%;

    & > img, & > video {
        width: 100%;
        height: auto;
    }

}

.image {
    background-color: #ffffff;
    display: block;
    border-radius: 4px;
}
.hover {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;
    width: 100%;
    display: block;
    visibility: hidden;
    height: auto;
    transition: opacity 200ms ease-in-out;
    @media not (hover: none) {
        display: none !important;
    }
}

.imageBordered {
    box-shadow: 0 0 0 1px #dadada;
    border-radius: 4px;
}

.livePreview {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: block;
    background-color: #fff;
    background-size: cover;
    background-position: top center;
    border-radius: 4px;
    animation: opacity 300ms ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: block;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    //delay together with opacity prevents blinking when
    //loading is super fase
    opacity: 0;
    animation-delay: 500ms;
    background: #f6f7f8;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 8%,
        rgba(255, 255, 255, 0.5) 38%,
        rgba(255, 255, 255, 0) 54%
    );
    background-size: 1000px 300px;
}

@keyframes placeHolderShimmer {
    0% {
        opacity: 1;
        background-position: -380px 0;
    }
    100% {
        opacity: 1;
        background-position: 380px 0;
    }
}

.adminBtnWrapper {
    position: absolute;
    right: 5px;
    opacity: 0;
    bottom: 14px;
}

.adminBtn {
    border: none;
    box-shadow: none;
    display: inline-block;
    white-space: nowrap;
    align-items: center;
    vertical-align: middle;
    font-size: 11px;
    line-height: 1;
    border-radius: 2px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    padding: 6px;
    margin-right: 6px;

    transition: all 200ms linear;

    &:hover {
        opacity: 0.7;
    }

}
.adminBtnInverse {
    @extend .adminBtn;
    background-color: $color-bg-grey;
    color: #000;
}

.listTitles {
    display: flex;
    position: absolute;
    bottom: 5px;
    left: 5px;
    opacity: 0;
    flex-wrap: wrap-reverse;
    transition: opacity 200ms ease-in-out;
    li {
        display: flex;
        white-space: nowrap;
        align-items: center;
        height: 20px;
        vertical-align: middle;
        font-size: 11px;
        line-height: 1;
        border-radius: 2px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0 6px;
        padding-bottom: 4px;
        padding-top: 5px;
        margin-right: 5px;
        margin-top: 5px;
        letter-spacing: 0.5px;
    }
}

.listTitlesInverse {
    @extend .listTitles;
    li {
        background-color: $color-bg-grey;
        color: #000;
    }
}

.previewTitle {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 200ms ease-in-out;
    background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0) 100%
    );
    padding: 5px;
    padding-bottom: 3px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;

    li {
        white-space: nowrap;
        transition: transform 400ms ease-in-out;
        transition-delay: 200ms;
        font-size: $font-size-smaller;
        line-height: 1;
        color: #fff;

        display: inline-block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
    }
    &:hover {
        text-overflow: clip;
    }
}

.previewTitleInverse {
    @extend .previewTitle;
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0) 100%
    );

    li {
        color: #000;
    }
}

.ratioContainer {
    position: relative;
    z-index: 0;
    height: 0;
}

.ratioContainer > img, .ratioContainer > video {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
}

.ratioContainer > video {
    display: block;
}

.isLazy {
    opacity: 0;
    transition: all .2s linear;
}

.blurhash {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block !important;
}

.videoLoader {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.videoBadges {
    opacity: 1;
    transition: opacity 200ms linear;
}

.image + video {
    opacity: 0;
}

.image.posterHidden + video {
    z-index: 2;
    opacity: 1;
    transition: all .2s linear;
}
