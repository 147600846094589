@import '../general/variables';
@import 'Forms.module';

.loginCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #fff;
    width: 100%;
    border-radius: 4px;

    h1 {
        margin-bottom: 20px;
    }

    @media (max-width: $screen-sm) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: $screen-sm + 1px) and (max-width: $screen-md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: $screen-md) {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @media (min-width: $screen-md) {
        h1 {
            margin-bottom: 50px;
        }
    }
}

.loginFormWrapper {
    input:focus {
        box-shadow: 0 0 5px 0 rgba(0, 186, 255, 0.4);
    }
}

.loginHeader {
    text-align: center;
    margin-bottom: 30px;
    svg {
        width: 80px;
        height: 80px;
        display: inline-block;
    }
    .loginHeaderTitle {
        svg {
            width: 35px;
            height: auto;
            display: inline-block;
            margin-left: 10px;
            margin-bottom: -2px;
        }
    }
    .loginHeaderSubtitle {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: $font-size-normal;
        color: $color-font-grey;
    }
}

.loginFooter {
    color: #000;
    text-align: center;
    margin-bottom: 20px;
    a {
        color: $color-primary;
    }
}

.loginButton {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    button > div {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 8px;
        }
    }
}
.federatedLoginButton {
    @extend .loginButton;
    flex-direction: column;
    position: relative;
    .centeredOneLiner {
        margin-bottom: 25px;
        color: $color-font-grey;
        font-size: $font-size-small;
        width: 100%;
        display: grid;
        grid-gap: 8px;
        grid-auto-flow: column;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 5px;
        }
    }
}

.signupButton {
    display: flex;
    margin-bottom: 10px;
    button {
        position: relative;
    }
}

.iconRight {
    position: absolute;
    left:20px;
}

.loginSplitButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    > * {
        margin-left: 5px;
        margin-right: 0;
        flex-basis: 50%;
        position: relative;

        &:first-child {
            margin-right: 5px;
            margin-left: 0;
        }
    }
}

.loginError {
    color: $color-error;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    a {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
}

.loginNote {
    margin-top: 10px;
    font-size: $font-size-small;
    color: $color-font-grey;
}

.loginPasswordResetBtn, .signupLink {
    color: $color-font-grey;
    &:focus {
        text-decoration: none;
    }
}

.loginSuccess {
    display: flex;
    align-items: center;
    color: $color-font-grey;
    height: 100%;
    svg {
        margin-right: 10px;
    }
}

@supports (display: grid) {
    .loginFormTwoCols {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        .inputLarge {
            margin-bottom: 0;
        }

        .btn {
            display: block;
            margin: 0;
        }
        .loginSplitButtons {
            display: block;
        }
    }

    .loginFormThreeCols {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        .inputIconWrapper{
            margin: 0;
        }
        .inputLarge {
            margin-bottom: 0;
        }

        .btn {
            display: block;
            margin: 0;
        }
        .loginSplitButtons {
            display: block;
        }

        @media (min-width: $screen-sm) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 600px) {
    .loginFormThreeCols {
        grid-template-columns: 1fr;
    }
}
