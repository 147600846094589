@import '../../../../styles/general/variables';

.wrapper {
    width: 300px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: absolute;

    z-index: 999;
    right: 0;
    font-size: $font-size-small;
    @media (max-width: calc(1785px + 60px)) {
        // 1785px is $container large
        right: 20px; //fallback
        right: 1.5vw;
    }
}

.detailsRow {
    display: flex;
    justify-content: space-between;

}

.label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
}

.link {
    flex: 0;
    padding-left: 20px;
    white-space: nowrap;
    color: $color-font-grey;
}

.LinkWrapper {
    width: 100%;
    position: relative;
}
