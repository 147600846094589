.badges {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
}

.badge {
    display: block;
    width: auto;
    height: 20px;
    margin-right: 5px;
}

.scheduledBadge {
    @extend .badge;
    font-size: 11px;
    color: #fff;
    background-color: #0041ff;
    border-radius: 2px;
    display: flex;
    padding: 0 5px;
    align-items: center;
}
