@import '../../../styles/general/variables';

.wrapper{
    display: flex;
    width: 100%;
    transform-origin: center center;
    transform: rotate(-10deg) translateY(3%);
    @media (max-width: $screen-md) {
        transform: none;
    }
}

.col {
    width: 33.333%;
    padding: 40px 20px;
    @media (max-width: $screen-lg) {
        padding: 20px 10px;
    }

}
.colMiddle{
    width: 33.333%;
    padding: 0 20px;
    @media (max-width: $screen-lg) {
        padding: 0 10px;
    }

}

.image{
    display: block;
    border-radius: 4px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    @media (max-width: $screen-lg) {
        margin-bottom: 20px;
    }
}

.imageWithShadow{
    @extend .image;
    z-index: 2;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.30);
}
