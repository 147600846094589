@import '../../styles/general/variables';

.row {
    display: flex;
    margin-bottom: 100px;
    position: relative;

    @media (max-width: $screen-md) {
        flex-direction: column;
    }
}

.rowLargeMargin {
    composes: row;
    margin-bottom: 200px;

    @media (max-width: $screen-md) {
        margin-bottom: 160px;
    }
}

.rowNoMargin {
    composes: row;
    margin-bottom: 0;
}

.rowBlue {
    composes: row;
    border-radius: 4px;
    background-color: #ecfaff;
    padding: 10% 0;
    position: relative;

    @media (max-width: $screen-md) {
        flex-direction: column;
        padding: 80px 0;
    }
}

.rowVideo {
    composes: row;
    padding: 5% 0;
}

.half {
    flex: 1;
    display: flex;
}

.contentLeft {
    composes: half;
    justify-content: flex-start;
    padding-right: 30px;
    @media (max-width: $screen-md) {
        order: 2;
    }
}
.contentRight {
    composes: half;
    justify-content: flex-end;
    padding-left: 30px;
  @media (max-width: $screen-xl) {
    padding-right: 30px;
  }


    @media (max-width: $screen-md) {
        order: 2;
        justify-content: flex-start;
        padding: 0;
    }
}

.imageWrapper {
    composes: half;
    align-items: center;
    position: relative;
    @media (max-width: $screen-md) {
        order: 1;
        margin-bottom: 50px;
    }
}

.largeImage {
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.absoluteHalf {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    z-index: 1;
    @media (max-width: $screen-md) {
        max-width: 100%;
        width: 100%;
        position: relative;
        left: 0;
        transform: none;
        margin-bottom: 40px;
    }
}

.absoluteHalfOffset {
    position: absolute;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 55%;
    @media (max-width: $screen-md) {
        max-width: 100%;

        position: relative;
        left: 0;
        transform: none;
        margin-bottom: 40px;
    }
}

.introVideo {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.30);
}
