@import '../../../styles/general/variables';

.modal {
    border-radius: 4px;
    z-index: 999;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    will-change: transform;

    @media (max-width: $screen-md) {
        height: 90%;
        max-height: 600px;
    }
    @media (max-width: $screen-sm) {
        width: 100%;

        max-height: 700px;
    }

    @media (max-height: 650px) {
        width: 90%;
        height: 90%;
        max-height: 600px;
    }
}

.modalSmall {
    @extend .modal;
    max-width: 450px;

    &.modalPushed {
        @media (min-width: $screen-md) {
            top: -92px;
        }
    }
}

.modalExtraSmall {
    @extend .modal;
    max-width: 450px;

    height: auto;

    @media (max-width: $screen-md) {
        height: auto;
    }
    @media (max-width: $screen-sm) {
        height: auto;
    }

    @media (max-height: 650px) {
        height: auto;
    }
}

.modalMedium {
    @extend .modal;
    max-width: 900px;
}

.modalLarge {
    @extend .modal;
    max-width: 1280px;
    width: 100%;
    max-height: 700px;
    overflow: hidden;
    animation: none !important;
}
.modalFull {
    @extend .modal;
    max-width: 1545px;
    width: 100%;
    height: 90%;
    max-height: 900px;
    overflow: hidden;
    animation: none !important;
}

.modalWhite {
    background-color: #fff;
}

.modalBlack {
    background-color: #000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 4.5% 1.5% 10px;
    will-change: opacity;
    @media (max-width: $screen-sm) {
        align-items: center;
    }
    @media (max-height: 820px) {
        padding: 1% 1% 10px;
    }

    &.modalCentered {
        align-items: center;
        padding-top: 10px;
    }
}

.close {
    position: absolute;
    right: 0;
    top: 2px;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    z-index: 100;
    transition: all 200ms linear;

    &:hover, &:focus {
        opacity: 0.6;
    }
}

//:global(.ReactModal__Body--open) {
//    overflow: hidden;
//}
//
//:global(.ReactModal__Overlay--after-open) {
//    animation: fadeIn 300ms ease-in;
//    animation-fill-mode: both;
//}
//
//:global(.ReactModal__Overlay--before-close) {
//    animation: fadeOut 300ms ease-out;
//    animation-fill-mode: both;
//}
//
//:global(.ReactModal__Content--after-open) {
//    animation: fadeInDown 300ms ease-in;
//    animation-fill-mode: both;
//}
//
//:global(.ReactModal__Content--before-close) {
//    animation-fill-mode: both;
//    animation: fadeOutDown 300ms ease-out;
//}

@keyframes fadeInDown {
    from {
        transform: translateY(5%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fadeOutDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(5%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
