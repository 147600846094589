.userTrialSliderTrack{
  margin:  10px 0;
  background: #E6E6E6;
  width: 100%;
  border-radius: 10px;
}
.userTrialSlider{
  height: 3px;
  background-color: #FFBA00;
  background-image: linear-gradient(-225deg, #FFBA00 0%, #FF4D00 100%);
  border-radius: 10px;
}
