@import '../../../styles/general/variables';

.collection {
    display: block;
    margin-bottom: $space-lg;
}
.collectionTitle {
    margin-bottom: 10px;
}
.collectionDescription {
    margin-bottom: 20px;
    height: 20px;
    display: block;
    @media (max-width: $screen-lg) {
        height: 40px;
    }
    @media (max-width: $screen-md) {
        height: 80px;
    }
}
.featuredMockups {
    padding: 18px 0;
    display: block;
    span {
        display: grid;
        position: relative;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 18px;
        width: 100%;
        :global(.react-loading-skeleton) {
            padding-top: 75%
        }
        @media (max-width: $screen-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.featuredMockupsHalf {
    span {
        grid-template-columns: repeat(2, 1fr);
    }
}

