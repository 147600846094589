@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    & > * {
        width: 50%;
        padding: $space-xs;
    }
}

@supports (display: grid) {
    .wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 8px;
        width: 100%;
        margin: $space-sm auto;

        & > * {
            width: 100%;
            padding: 0;
            @media (max-width: $screen-lg) {
                width: 100%;
            }
            @media (max-width: $screen-sm) {
                width: 100%;
                margin: 0;
            }
        }
    }
}
