@import '../../../styles/general/variables';

.filters {
    display: flex;
    flex-wrap: wrap;
    order: 2;
    @media (max-width: $screen-lg) {
        flex-wrap: nowrap;
    }

    @media (min-width: $screen-md) {
        order: 1;
    }
}

.filterWrapper {
    position: relative;
    button {
        font-weight: 600;
        padding: 17px 25px;
        background: $color-bg-violet;
        color: $color-font-violet;
        transition: all 100ms ease-in-out;
        top: 5px;
        position: relative;
    }
    li {
        margin-right: 10px;
        margin-top: 15px;
        button {
            top: 0px;
        }
        &:hover {
            button {
                text-decoration: none;
                top: -5px;
            }
        }
    }
}

.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    border-right: 1px solid $color-border;
    padding-left: 20px;
    &:last-child {
        border-right: none;
        padding-right: 0;
    }
    &:first-child {
        padding-left: 0px;
    }
}

.filterButton {
    display: inline-block;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    font-size: $font-size-small;
    color: $color-font-main;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.filterButtonActive {
    @extend .filterButton;
    text-decoration: none;
}

.filterItem {
    list-style: none;
    text-align: center;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}
