@import '../../../styles/general/variables';

.outerContainer {
    max-width: 100%;
}

.sliderWrapper {
    position: relative;

    display: flex;
    z-index: 1;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        display: none;
        background: transparent; /* Chrome/Safari/Webkit */
    }

    @media (max-width: $screen-lg) {
        overflow-x: auto;
    }

    .container {
        position: relative;
    }
    .slidebar {
        transition: 300ms linear;
        display: block;
        position: relative;
    }
}

.center {
    justify-content: center;
}
