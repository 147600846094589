@import '../../../styles/general/variables';
.navbarContainer {
    max-width: 100%;
}
.navbar {
    display: inline-block;
    width: 100%;
    @media (max-width: $screen-md) {
        overflow: hidden;
        white-space: nowrap;
        min-height: 40px;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }
    }
}
.navItem {
    list-style: none;
    display: inline-block;
}
.navLink {
    margin-right: 30px;
    padding: 9px 0;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    color: $color-font-main;
    white-space: nowrap;
    cursor: pointer;
    &:hover, &:focus {
        color: $color-font-main;
        text-decoration: underline;
    }
    sup {
        font-size: 0.65rem;
        color: $color-error;
        text-transform: uppercase;
        margin-left: 5px;
    }
    @media (max-width: $screen-xl) {
        margin-right: 25px;
    }
}
.navSeparator {
    @extend .navLink;
    &:hover {
        cursor: default;
        //text-decoration: none;
        @media (max-width: $screen-md) {
            cursor: pointer;
        }
    }
}

.navArrow {
    margin-left: 8px;
}

.navLinkActive {
    composes: navLink;
    color: #000;
}

.dropdown {
    position: static !important;
}

.dropdownContent {
    bottom: auto;
    top: 40px;
    min-width: 160px;
    width: auto;
    padding: 0px;
    transform: none;

    &:after {
        display: none;
    }

    @media (max-width: $screen-md) {
        position: absolute;
        left: 0 !important;
        top: 110px !important;
        width: 100%;
        padding: 8px 15px;
    }
}

.dropdownContentTwoCols {
    @extend .dropdownContent;
}

.dropdownList {
    display: block;
}

.dropdownListTwoCols {
    @extend .dropdownList;
}

@supports (display: grid) {
    .dropdownContentTwoCols, .dropdownContent {
        @media (min-width: $screen-md) {
            padding: 15px 30px 15px 20px;
        }
    }
    .dropdownListTwoCols {
        display: grid;
        column-gap: 30px;
        grid-template-columns: max-content max-content;
        @media (max-width: $screen-md) {
            grid-template-columns: 100%;
        }
    }
}
