@import '../../../styles/general/variables';

.navbarWrapper {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
}

.navbar {
    max-width: $container-large;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 !important;
    @media (max-width: $screen-xl) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.left {
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: calc(#{$container-large} + 60px)) {
        padding-left: 20px;
        padding-left: 1.5vw;
    }

    @media (max-width: $screen-sm) {
        padding-right: 0px;
        padding-left: 15px;
    }
}
.center {
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    width: 100%;
    @media (max-width: $screen-lg) {
        width: 100%;
        order: 3;
        white-space: nowrap;
        justify-content: center;
        padding: 4px 15px;
        border-top: 1px solid #dfe1e9;
    }
}

.right {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (max-width: calc(#{$container-large} + 60px)) {
        padding-right: 20px;
        padding-right: 1.5vw;
    }
    @media (max-width: $screen-sm) {
        padding-right: 15px;
    }
}

.logo {
    display: block;
    height: auto;
    width: 198px;

    @media (max-width: $screen-sm) {
        width: 60px;
        height: 30px;
    }
}

.logoMobile {
    @media (max-width: $screen-sm) {
        display: none;
    }
}

.noUserNavbar {
    max-width: $container-large;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 !important;
    @media (max-width: $screen-lg) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.noUserCenter {
    display: flex;
    flex: 1;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: $screen-lg) {
        flex: none;
        width: 100%;
        order: 3;
        white-space: nowrap;
        justify-content: center;
        padding: 4px 15px;
        overflow: hidden;
        border-top: 1px solid #dfe1e9;
    }
}

.loginCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    flex: 1;
}

.navbarWrapperSoonerWrap {
    .navbar {
        @media (max-width: 1650px) {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .center {
        @media (max-width: 1650px) {
            flex: none;
            width: 100%;
            order: 3;
            white-space: nowrap;
            justify-content: center;
            padding: 4px 15px;
            overflow: hidden;
            border-top: 1px solid #dfe1e9;
            text-align: center;
        }
    }
}

.usermenuWrapper{
    height: 30px;
    display: flex;
    align-items: center;
}
