@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.content {
}

.titleWrapper {
    display: inline-block;
}

.subcategoryTitle {
    font-size: $font-size-small;
    white-space: nowrap;
    text-decoration: none;
    border-radius: 4px;

    &:hover {
        color: $color-font-main;
        text-decoration: underline;
    }
}

.newBadge{
    margin-left: 5px;
    font-size: 0.65rem;
    color: $color-error;
    text-transform: uppercase;
}

.subcategoryTitleHover {
    @extend .subcategoryTitle;
    text-decoration: underline;
}

.subtitleWrapper {
    color: $color-font-grey;
    white-space: nowrap;
}

.subtitle {
    color: $color-font-grey;
    text-decoration: none;
    font-size: 12px;
    &:hover {
        color: $color-font-grey;
        text-decoration: underline;
        sup {
            text-decoration: none;
        }
    }
}
.subtitleNoHover {
    @extend .subtitle;
    &:hover {
        text-decoration: none;
    }
}

.subcategoryIcon {
    display: block;
    width: 36px;
    height: 36px;
    margin-right: 14px;

}
