@import '../../../styles/general/variables';

.footer {
    padding: 50px 0 120px;
    /*css grid fallback start*/
    display: flex;
    width: 100%;
    & > * {
        flex: 1;
    }

    /*css grid fallback end*/

    @media (max-width: $screen-sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@supports (display: grid) {
    .footer {
        width: auto;

        display: grid;
        grid-template-columns: 4fr repeat(3, 1fr);
        grid-gap: 45px;

        @media (max-width: $screen-lg) {
            grid-gap: 40px;
        }
        @media (max-width: $screen-md) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);
            grid-gap: 10px;
        }

        @media (max-width: $screen-sm) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.footerContent {
    padding-bottom: 20px;
    min-width: 230px;
    @media (max-width: $screen-md) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        grid-column: span 3;
        align-items: center;
        order: 4;
        padding-top: 40px;
        text-align: center;
    }

    @media (max-width: $screen-sm) {
        order: 4;
        align-items: center;
        padding-top: 40px;
        text-align: center;
    }
}

.footerContentHolder {
    max-width: 430px;

    @media (max-width: $screen-md) {
        margin: 0 auto;
    }
}

.footerContentTop {
    a {
        display: inline-block;
    }

    @media (max-width: $screen-md) {
        text-align: center;
    }
}

.languagePickerRow{
    margin-top: 20px;
    margin-bottom: 20px;
}

.languagePickerWrapper{
    display: inline-block;
    vertical-align: middle;
    width: 250px;
    padding-right: 20px;

    @media (max-width: $screen-md) {
        margin: 0 auto;
    }
}

.footerRight {
    display: flex;

    padding-left: 30px;

    @media (max-width: $screen-md) {
        padding: 0;
    }
    @media (max-width: $screen-sm) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}

.footerCol {
    @media (max-width: $screen-sm) {
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: $screen-md) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.footerNav {
    position: relative;

    input[type="checkbox"] {
        display: none;
    }

    @media (max-width: $screen-md) {
        border-bottom: 1px solid $color-border-light;

        input[type="checkbox"] {
            position: absolute;
            display: inline-block;
            right: 0;
            top: -10px;
            width: 100%;
            height: 40px;
            opacity: 0;
            cursor: pointer;
            z-index: 2;

            &:checked + div {
                h3 > span {
                    transform: rotate(180deg);
                }
            }

            &:checked + div ~ ul {
                height: auto;
                opacity: 1;
            }
        }

        ul {
            overflow: hidden;
            height: 0;
            opacity: 0;

            -webkit-transition: all 200ms linear;
            -moz-transition: all 200ms linear;
            -o-transition: all 200ms linear;
            transition: all 200ms linear;
        }
    }
}

.footerNavTitle {
    margin-bottom: 35px;
    list-style: none;
    white-space: nowrap;

    h3 {
        color: $color-font-grey;
        text-transform: uppercase;
        font-size: $font-size-normal;

        & > span {
            display: none;
        }
    }

    @media (max-width: $screen-sm) {
        margin-bottom: 10px;
    }

    @media (max-width: $screen-md) {
        position: relative;
        text-align: left;

        h3 > span {
            position: absolute;
            display: inline-block;
            right: 16px;
            top: 0;

            -webkit-transition: all 200ms linear;
            -moz-transition: all 200ms linear;
            -o-transition: all 200ms linear;
            transition: all 200ms linear;
        }
    }
}

.footerNavItem {
    list-style: none;
    display: block;
    cursor: pointer;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    }
    a, button {
        padding: 15px 0;
        text-decoration: none;
        display: inline-block;
        color: $color-font-main;
        font-size: $font-size-small;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    button {
        text-align: left;
    }
    @media (max-width: $screen-sm) {
        a, button {
            padding: 5px 0;
        }
    }

    @media (max-width: $screen-md) {
        text-align: left;
        padding: 8px 0;
    }
}

.footerLowerContent {
    font-size: $font-size-small;
    color: $color-font-grey;

    @media (max-width: $screen-md) {
        font-size: 0.8rem;
    }
    @media (max-width: $screen-sm) {
        text-align: center;
    }
}

.footerLowerPayment {
    margin: 20px 0;
}

.footerSocialNav {
    display: inline-block;
    font-size: $font-size-small;
    color: $color-font-grey;
    vertical-align: middle;
    margin-top: 5px;

    @media (max-width: $screen-xsm) {
        margin-top: 20px;
    }
}

.footerSocialNavItem {
    display: inline-block;
    margin-right: 15px;

}

.footerSocialLabel {
    margin-right: 15px;
    @media (max-width: $screen-xl) {
        display: none;
    }
}

.trustpilot {
    margin-top: 20px;
    margin-bottom: 20px;

    @media (min-width: $screen-md) {
        position: relative;
        left: -15px;
    }
}
