@import '../../../styles/general/variables';

.titleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    align-items: center;

    @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
    }
}

.description {
    max-width: 450px;
    color: $color-font-grey;
    line-height: $line-height-large;
    a {
        color: $color-font-main;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @media (max-width: $screen-md) {
        font-size: $font-size-small;
    }
}

.grid {
    display: flex;
    flex: 1;
    & > * {
        width: 20%;
        padding: 10px;
    }
}
@supports (display: grid) {
    .grid {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        grid-gap: 20px;
        & > * {
            width: inherit;
            padding: inherit;
        }

        @media (max-width: $screen-sm) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }
    }
}

.item {
    display: block;
    width: 100%;
    @media (max-width: $screen-md) {
        &:nth-child(5) {
            display: none;
        }
    }
}

.image {
    border-radius: 4px;
    display: block;
    width: 100%;
}
