@import '../../../styles/general/variables';

.wrapper{
    max-width: 500px;
}

.description{
    color: $color-font-grey;
    margin-bottom: 40px;
}

.descriptionBlue {
    composes: description;
    color: #829DA7;
}

