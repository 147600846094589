@import '../../../styles/general/variables';

.wrapper {
    position: relative;
    border-radius: 4px;
}

.svg {
    width: 100%;
    display: block;
    border-radius: 4px;
}

.placeholder {
    overflow: hidden;
    width: 100%;
    display: block;
    border-radius: 4px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shine;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #f5f5f5 18%, #eeeeee 33%);
    background-size: 1200px 100px;
    position: relative;
}

@keyframes shine{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
