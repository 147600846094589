@import '../../../styles/general/variables';

.subtitle {
    text-transform: initial;
}

.titleBlue {
    color: $color-orange;
}

.subtitleBlue {
    color: #004660;
}

.titleInverse {
    color: $color-font-blue-light;
}

.subtitleInverse {
    color: #ffffff;
}

