@import '../../../styles/general/variables';

.colorGrey {
    fill: $color-font-grey;
    g,
    path {
        fill: $color-font-grey;
    }
}

.colorBlueLight {
    fill: $color-font-blue-light;
    g,
    path {
        fill: $color-font-blue-light;
    }
}

.colorWhite {
    fill: #ffffff;
    g,
    path {
        fill: #ffffff;
    }
}

.colorGreen {
    fill: $color-confirm;
    g,
    path {
        fill: $color-confirm;
    }
}

.colorOrange {
    fill: $color-orange;
    g,
    path {
        fill: $color-orange;
    }
}

.colorBlack {
    fill: $color-font-main;
    g,
    path {
        fill: $color-font-main;
    }
}

.colorRed {
    fill: $color-error;
    g,
    path {
        fill: $color-error;
    }
}

.colorBlue {
    fill: $color-primary;
    g,
    path {
        fill: $color-primary;
    }
}

.colorLightGreen {
    fill: #9bd0c3;
    g,
    path {
        fill: #9bd0c3;
    }
}

.colorLightOrange {
    fill: #c18457;
    g,
    path {
        fill: #c18457;
    }
}
.colorPurple {
    fill: #b96dd7;
    g,
    path {
        fill: #b96dd7;
    }
}
