.wrapper{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  max-width: 720px;
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
