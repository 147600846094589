@import '../../../styles/general/variables';

.wrapper, .wrapperNoPadding {
    position: relative;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;

    border-radius: 4px;

    @media (max-width: $screen-md) {
        position: absolute;
    }
    @media (max-height: 650px) {
        position: absolute;
    }

    & > section {
        height: auto !important;
    }

}

.wrapper {
    padding: 30px;
}
