@import '../../../../styles/general/variables';

.upgradeBtn {
    margin-right: 0;
    text-decoration: none;
    background-color: #f6c423;
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin-right: 10px;
            display: block;
            height: 10px;
            width: auto;
        }
    }
    @media (max-width: $screen-sm) {
        display: none;
    }

    span {
        color: #fff;
    }
    &:hover {
        background-color: darken(#f6c423, 5%);
    }
    &:focus {
        background-color: #f6c423;
    }
}
