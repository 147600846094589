@import '../../../styles/general/variables';

.toolBar {
    margin-top: 30px;

    button {
        span {
            display: inline-block;
            vertical-align: middle;
        }

        svg {
            position: relative;
            top: 3px;
        }
    }

    .create, .rename {
        font-size: $font-size-small;
        color: $color-font-main;
    }

    .create {
        @media (max-width: $screen-md) {
            width: 100%;
        }

        @media (min-width: $screen-md) {
            padding-right: 75px;
            padding-left: 75px;
        }

        svg {
            top: 1px;
            margin-right: 5px;
        }
    }

    .rename {
        margin-right: 10px;
        svg {
            margin-right: 10px;
        }
    }
}
