@import '../../../styles/general/variables';

.list{

}

.item{
    list-style: none;
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    &:last-of-type{
        margin-bottom: 0;
    }
}

.icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    display: block;
}

.content{

}

.contentNotAvailable{
    color: $color-font-grey;
}
