@import '../../../styles/general/variables';

.wrapper {

}

.title {
    margin-bottom: 10px;
    svg{
      margin-left: 10px;
    }
}

.description {
    color: $color-font-grey;
    margin: 0;
}

.titleGrey {
  composes: title;
  color: $color-font-grey;
}


.titleBlue {
    composes: title;
    color: #004660;
}

.descriptionBlue {
    composes: description;
    color: #829DA7;
}

.icon{
  margin-bottom: 20px;
}
