@import '../../styles/general/variables';
@import '../../styles/modules/Login.module';

.loginContainer {

    display: flex;
    flex: 1;
}

.pageSlit {
    display: flex;
    flex: 1;
    padding: 20px;
    width: 100%;

    @media (min-width: $screen-md) {
        padding: 40px;
    }

    @media (min-width: $screen-sm + 1px) and (max-width: $screen-md) {
        padding-bottom: 85px;
    }
}

.loginCol {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.signUpCta {
    display: flex;
    align-items: center;
    color: $color-font-grey;
}

.logoArea {
    display: flex;
    align-self: flex-start;
}

.bottomWrapper{
    margin-top: 30px;
    font-size: $font-size-small;
    text-align: center;
}

.bottomCta {
    display: inline-block;
    margin-left: 18px;

}

.sep {
    color: $color-font-grey;
    padding-right: 8px;
    padding-left: 8px;
}

.testimonialCol {
    flex: 0;
    max-width: 50%;
    display: flex;

    @media (max-height: 710px) {
        display: none;
    }
    @media (max-width: $screen-md) {
        display: none;
    }
}
