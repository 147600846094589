@import '../../../styles/general/variables';

.notificationCenter {
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width: $screen-sm) {
        display: none;
    }
}

.notificationCenterBtn {
    cursor: pointer;
    font-size: $font-size-small;
    display: flex !important;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    img {
        margin-right: 10px;
        display: block;
        height: 15px;
        width: auto;
    }
    @media (max-width: 1650px) {
        .label{
            display: none;
        }
        img {
            height: 17px;
            margin-right: 5px;
            width: auto;
        }
    }
}

.notificationCenterDropdownContent {
    width: 280px;
    bottom: auto;
    top: 30px;
    padding: 10px;
    font-size: $font-size-small;
}

.notificationCenterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px 18px;
    a {
        color: #B3B3B3;
        font-size: $font-size-small;
    }
    h4 {
        margin-bottom: 0;
    }
}

.notificationCenterInner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 422px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 40px;
        display: block;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        pointer-events: none;

    }
}

.notificationCenterScroll {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.notificationItem {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #dbdbdb;
    &:first-child {
        margin-top: 0;
    }
}

.notificationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.notification-highlight {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-error;
}
.notificationTtitle {
    margin-top: 0;
    margin-bottom: 0px;
}

.notificationDate {
    margin-bottom: 10px;
}
.notificationContent {
    line-height: 1.6;
    color: $color-font-grey;

    a {
        color: $color-primary;
        &:hover {
            color: $color-primary-dark;
        }
    }
    ul {
        padding-left: 15px;
        li {
            list-style: disc;
        }
    }
    p:last-of-type {
        margin-bottom: 0;
    }
    img {
        display: none !important;
    }
    video {
        max-width: 100%;
        height: auto;
        display: block;
        width: auto;
    }
}

.notificationImage {
    margin-bottom: 15px;
    img {
        max-width: 100%;
        height: auto;
        display: block;
        width: auto;
    }
}
.notificationVideo {
    margin-bottom: 15px;
    video {
        max-width: 100%;
        height: auto;
        display: block;
        width: auto;
    }
}
.notificationCenterReadMore {
    color: $color-font-main;
}

.badge {
    display: block;
    position: absolute;
    top: -4px;
    left: 11px;
    border: 2px solid white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $color-error;
    font-size: 8px;
    color: white;
    font-weight: normal;
    text-align: center;
    line-height: 12px;
}
