@import '../../../styles/general/variables';

.wrapper{
    position: relative;
    display: flex;
    align-items: center;
}

.dropdown{
    min-width: 200px;
    width: auto;
    bottom: auto;
    top: 30px;
    right: 0px;
    padding: 10px;
    font-size: $font-size-small;
    &:after{
        display: none;
    }
}

.dropdownItem{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    sup {
        font-size: 0.65rem;
        color: $color-error;
        text-transform: uppercase;
        margin-left: 5px;
    }

    &:hover{
        background-color: $color-bg-grey;
        text-decoration: none;
    }
}

.logoutBtn{
    margin-top: 10px;
    color: #B3B3B3;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
}

.count{
    color: $color-font-grey;
    margin-left: 10px;
}
.upgradeLink{
    text-decoration: none;
}

.upgradeBtn {
    margin-right: 0;
    text-decoration: none;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            margin-right: 10px;
            display: block;
            height: 10px;
            width: auto;
        }
        @media (max-width: $screen-sm) {
            display: none;
        }
    }
}

.userName {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;

    border-bottom: 1px solid #DBDBDB;

    span {
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        max-width:200px;
        display:inline-block;
    }
}
