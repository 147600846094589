@import '../../../styles/general/variables.scss';

@mixin padding {
    padding: 0 20px; //fallback
    padding: 0 2vw;
}

.containerLarge {
    max-width: $container-large;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @media (max-width: calc(#{$container-large} + 60px)) {
        @include padding
    }
}

.container {
    max-width: $container;
    width: 100%;
    margin: 0 auto;

    @media (max-width: calc(#{$container-large} + 60px)) {
        @include padding;
    }
}

.containerSmall {
    max-width: $container-small;
    width: 100%;
    margin: 0 auto;
    @include padding;
}

.noPadding {
    padding: 0px;
}
