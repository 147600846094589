@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    align-items: center;
    margin-left: 20px;

    cursor: pointer;
}

.icon {
    height: 29px;
    width: 29px;
    background-color: #00baff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #fff;
}

.image {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: none;
    display: block;
    flex: 0;
    border-radius: 50%;
    @media (max-width: $screen-sm) {
        margin-right: 0;
    }
}
