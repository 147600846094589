@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    height: 600px;
    overflow: hidden;

    @media (max-width: $screen-md) {
        height: 90vh;
    }
    @media (max-height: 640px) {
        height: 90vh;
    }
}

.left {
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 50%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;

    @media (max-width: $screen-md) {
        display: none;
    }

    img {
        width: 100%;
    }
}

.right {
    height: 100%;
    width: 50%;
    position: relative;

    @media (max-width: $screen-md) {
        width: 100%;
    }
}

.buttonsGroup {
    display: flex;

    button {
        width: 100%;
    }

    button:first-child {
        margin-right: 5px;
    }

    button:last-child {
        margin-left: 5px;
    }

}

.wrapperForm {
    padding: 0 8px;

    button {
        width: 100%;
    }

    form {
        border-bottom: 1px solid $color-border-light;
        padding-bottom: 20px;
    }
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.inputModal {
    padding-top: 11px;
    padding-bottom: 11px;
}

.messages {
    padding: 10px;
    margin-top: 16px;
    border-radius: 4px;
    text-align: center;

    svg {
        position: relative;
        top: 2px;
        margin-right: 8px;
    }

    &.error {
        background-color: lighten($color-error, 48%);
        color: $color-error;
    }

    &.success {
        background-color: lighten($color-confirm, 58%);
        color: $color-confirm;
    }
}
