@import '../../../styles/general/variables';

.content {
  margin-bottom: $space-lg;
  padding: 70px 50px 40px;
  text-align: center;

  @media (max-width: $screen-md) {
    align-items: center;
    text-align: center;
    padding: 80px 20px;
  }

}
.btn {
  min-width: 175px;
  margin: 40px auto;
}
