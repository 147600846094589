@import '../../../styles/general/variables';

.pageHeadlineSm{
    font-size: $font-size-normal;
    color: $color-font-grey;
    margin-bottom: 10px;
    text-transform: uppercase;
    &.inverse {
        color: $color-font-blue-light;
    }
    &.blue {
        color: $color-orange;
    }
}

.pageHeadlineLgSmaller {
    @extend .pageHeadlineLg;
    font-size: $font-size-larger;
    @media (max-width: $screen-lg) {
        font-size: $font-size-large;
    }
    @media (max-width: $screen-sm) {
        font-size: 1.5rem;
    }
}

.pageHeadlineLg {
    font-size: $font-size-larger;
    color: $color-font-main;
    margin-top: 0;
    line-height: $line-height-base;
    margin-bottom: 0;
    white-space: pre-line;
    &.inverse {
        color: $color-font-white;
    }
    &.blue {
        color: #004660;
    }

    @media (min-width: 1845px) {
        font-size: $font-size-largest;
    }
}

