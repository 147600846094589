@import '../../../styles/general/variables';

.wrapper{
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0 0 0;
    text-align: center;
    background-color: $color-bg-grey;
}

.upgradeButton {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 0;
}
