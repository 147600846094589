@import '../../../../styles/general/variables';

.wrapper {
    width: 300px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: absolute;

    z-index: 999;
    right: 0;
    font-size: $font-size-small;
    @media (max-width: calc(1785px + 60px)) {
        // 1785px is $container large
        right: 20px; //fallback
        right: 1.5vw;
    }
}

.detailsRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
}

.progress {
    flex: 0;
    padding-left: 20px;
    white-space: nowrap;
    color: $color-font-grey;
}

.progressBarWrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    background-color: #dcdcdc;
}
.progressBar {
    height: 4px;
    border-radius: 2px;
    background-color: $color-confirm;
    transition: background-color, width 200ms ease-in;
}

.dots {
    position: relative;
    display: inline-block;
    margin-left: 2px;
}

.dot {
    display: inline-block;

    margin-right: 1px;

    animation: wave 1.3s ease-in-out infinite;

    &:nth-child(2) {
        animation-delay: -1.1s;
    }

    &:nth-child(3) {
        animation-delay: -0.9s;
    }
}

@keyframes wave {
    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-3px);
    }
}

@keyframes blink {
    0% {
        opacity: 0.05;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.05;
    }
}
