.button {
    appearance: none;
    -moz-box-align: center;
    align-items: center;
    display: block;
    -moz-box-pack: center;
    justify-content: center;
    margin: 0px;
    outline: currentcolor none medium;
    padding: 0 16px;
    user-select: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    min-width: 60px;
    width: auto;
    line-height: 35px;
    text-align: center;


    border-radius: 35px;
    height: 35px;
    transform: scale(1);
    z-index: 996;

    border: medium none;
    bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 7px;
    position: fixed;
    right: 40px;
    top: auto;
    transition: box-shadow 250ms ease 0s, opacity 0.4s ease 0s, scale 1000ms ease-in-out 0s, transform 0.2s ease-in-out 0s, background 0.2s linear;

    color: rgb(57, 73, 86);
    font-size: 14px;
    white-space: nowrap;

    &:hover {
        background-color: #e9e9e9;
    }
}

.active {
    display: none;
}
