@import '../../../styles/general/variables';

.grid {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    & > * {
        width: 50%;
        padding: 40px;
    }
}
@supports (display: grid) {
    .grid {
        flex: 1;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
        grid-gap: 60px;
        column-gap: 40px;
      & > * {
        width: inherit;
        padding: inherit;
      }

      @media (max-width: $screen-md) {
        grid-gap: 40px;
      }
    }
}


.gridSingle{
  max-width: 500px;
  display: block;
  & > * {
   margin-bottom: 30px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}
