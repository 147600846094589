@import '../../../styles/general/variables';

.livePreviewWrapper {
    margin-top: 30px;
    @media (min-width: $screen-sm + 1px) {
        margin-bottom: 30px;
    }
}

.buttonHolder {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 20px 0;

    .predefinedButton:last-child {
        margin-right: 0 !important;
    }

    @media (max-width: 573px) {
        display: block;
        text-align: center;

        & > div, & > span {
            display: block;
            text-align: center;
            width: 100% !important;
        }

        & > .livePreviewUploadButtonCompact, & > button {
            display: inline-block;
        }

        & > a {
            display: block;
            margin-left: 0;
            margin-top: 20px;
            & > button {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    /*
    @media (min-width: 1020px) and (max-width: 1241px) {
        display: block;

        & > div, & > span {
            display: block;
            text-align: center;
            width: 100% !important;
        }
    }

     */
}

.buttonLink {
    height: 50px;
    margin-left: 10px;

    span {
        margin-left: 15px;
    }
}

.livePreviewUploadButton {
    background-color: $color-font-violet;
    color: $color-font-white;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    border-radius: 4px;
    & > span {
        margin-left: 15px;
    }
    &:hover {
        background-color: darken($color-font-violet, 5%);
    }
}

.livePreviewUploadButtonCompact {
    @extend .livePreviewUploadButton;
    background-color: $color-border-light;
    width: 50px;
    height: 50px;
    padding: 0;
    margin-right: 10px;
    box-shadow: 0 0 0 1px $color-border-light;

    .uploadButtonBackground {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 500ms ease;
        background-size: cover;
        background-position: center center;
        &:hover {
            -webkit-filter: brightness(80%);
        }

        img {
            filter: drop-shadow( 0 0 3px rgba(0, 0, 0, .7));
        }
    }
}
.livePreviewRemoveButton {
    color: $color-font-white;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 4px;
    width: 50px;
    justify-content: center;
    border: 0;
    box-shadow: 0 0 0 1px $color-border-light;

    & > span {
        margin-left: 15px;
    }
    &:hover {
        background-color: darken($color-font-white, 5%);
    }
}

.livePreviewDescription {
    color: $color-font-grey;
    font-size: 14px;
    margin-left: 20px;
}

.predefinedButton {
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.predefinedButtonBorder {
    box-shadow: 0 0 0 1px $color-border-light;
}

.separator {
    width: 54px;
    height: 50px;
    display: inline-block;
    color: $color-font-grey;
    font-size: $font-size-small;
    text-align: center;
    line-height: 50px;
}

.info {
    color: $color-font-grey;
    font-size: $font-size-small;
}

.title {
    @media (max-width: 573px) {
        display: block;
        text-align: center;
    }
}
