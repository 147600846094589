@import '../../../styles/general/variables.scss';

.wrapper {
    //max-height: 0;

    position: relative;
    //transform: translateY(1);
    //transition: all 700ms ease-in-out;
    background-color: #002847;
    overflow: hidden;
    max-height: 300px;

    /*
    &.open {
        transform: translateY(0);
        max-height: 300px;

        & > a {
            padding: 20px;
        }
    }

     */

    & > a {
        text-decoration: none;
        display: block;
        padding: 20px;
    }
}
.left {
    position: absolute;
    left: 2%;
    bottom: -10%;
    z-index: 1;

    width: 71px;

    img {
        width: 100%;
    }

    @media (min-width: $screen-lg) {
        width: 118px;
        bottom: -20%;
    }
}

.right {
    text-align: center;
    position: relative;
    z-index: 2;

  @media (min-width: $screen-sm) {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
  }
}

.subtitle {
    color: $color-primary;

    @media (max-width: $screen-lg - 1) {
        font-size: $font-size-small;
    }
}

.center {
    text-align: center;
    position: relative;
    z-index: 2;
    color: #fff;
    max-width: 845px;
    margin: 0 auto;

    h1 {
        font-size: $font-size-normal-large;
        margin-bottom: 5px;

        @media (min-width: $screen-lg) {
            font-size: $font-size-large;
        }
    }

    p {
        margin: 0;
    }
    @media (max-width: $screen-sm - 1) {
        text-align: left;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-lg){
        max-width: 490px;
        //text-align: left;
        //margin: 0;
    }

  @media (max-width: $screen-sm) {
    text-align: center;
    margin-bottom: 10px;

  }
}

.close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-color: transparent;
    padding: 0;
    display: block;
    border: none;
    &:hover {
        background-color: transparent;
    }
    img {
        width: 16px;
        height: 16px;
        display: block;
    }
    @media (max-width: $screen-sm) {
        top: 10px;
        right: 15px;
    }
}
