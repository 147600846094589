@import '../../../styles/general/variables';

.customerTestimonials {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;


  &.single {
    margin-left: 0;
    margin-right: 0;
  }

    &:nth-child(2) {
    }
    @media (max-width: $screen-sm) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

.testimonialCol {
    width: 33.3333%;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    &.inverse {
    }

    &.single {
        width: 100%;
        padding: 0;
    }

    @media (max-width: $screen-sm) {
        width: 100%;
    }
}

.testimonialBox {
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 30px;
    display: inline-block;
    position: relative;

    font-weight: 500;
    line-height: 30px;
    font-size: $font-size-normal-large;
    color: #000;
    &.inverse {
        color: $color-font-white;
        background: transparent;
        &::after {
            display: none;
        }
    }

    @media (max-width: $screen-sm) {
        max-width: 100%;
        width: 100%;
    }

    &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%) rotate(45deg);
        background: #fff;
        width: 10px;
        height: 10px;
    }
}

.testimonialAvatar {
    display: flex;

    align-items: center;
}

.testimonialAvatarImg {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    display: block;
    font-size: $font-size-small;
}

.testimonialAvatarPerson {
    font-size: $font-size-small;
}

.testimonialAvatarName {
    color: $color-font-main;
    &.inverse {
        color: $color-font-white;
    }
}

.testimonialAvatarPosition {
    color: $color-font-grey;
    &.inverse {
        color: $color-font-blue-light;
    }
}

.trustpilot {
    padding-top: 70px;
}
