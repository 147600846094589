@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $space-lg;
    margin-bottom: $space-lg;
    border-bottom: 1px solid $color-border;
    @media (max-width: $screen-sm) {
        flex-direction: column;
        justify-content: center;
      text-align: center;
    }
}

.iconWrapper{
  max-width: 50%;
  @media (max-width: $screen-sm) {
    max-width: 100%;
    margin-top: 20px;
  }
}

.icon {
    display: block;
    max-width: 100%;
  height: auto;
  transform: translateX(20px);
  @media (max-width: $screen-sm) {
    transform: translateX(0px);
  }
}
