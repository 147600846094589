@import '../../../styles/general/variables';

.collectionButton {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    min-width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0;
    transition: all .2s linear;

    @media(max-width: $screen-md) {
        opacity: 1;
    }

    & > span {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        white-space: nowrap;
        padding: 0 8px;
        height: 20px;
        min-width: 20px;
        background-color: rgba(0,0,0,0.3);
        line-height: 20px;
        font-size: 10px;
        color: $color-font-white;
        border-radius: 2px;
        text-align: center;
        transition: all .2s linear;

        &:hover {
            background-color: rgba(0,0,0,0.5);
        }
    }

    svg {
        display: inline-block;
        position: relative;
        vertical-align: top;
        top: 5px;
        margin-right: 5px;
    }

    &.isolated {
        & > span {
            background-color: $color-bg-grey;
            color: $color-font-main;

            svg path {
                fill: $color-font-main;
            }

            &:hover {
                background-color: darken($color-bg-grey, 10%);
            }
        }
    }
}

.isActive {
    opacity: 1;

    span {
        background-color: $color-primary;
        padding: 0;
        &:hover {
            background-color: lighten($color-primary, 10%);
        }
        svg {
            margin: 0;
        }
    }
}
