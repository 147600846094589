@import '../../../styles/general/variables';

.wrapper {
    display: flex;
    align-items: center;
}

.listItem {
    list-style: none;
    margin-right: 30px;
    @media (max-width: $screen-lg) {
        display: none;
    }
}

.listButton {
  list-style: none;
    margin-right: 7px;
    &:last-of-type {
        margin-right: 0;
    }
  @media (max-width: $screen-xsm) {
    margin-right: 4px;
  }
}

.btn{
  @media (max-width: $screen-sm) {
    padding: 6px 6px;
  }
}


.link {
    color: $color-font-main;
    font-size: $font-size-small;
    text-decoration: none;
}

.longLabel {
  @media (max-width: 1600px) {
    display: inline;
  }
  @media (max-width: $screen-xl) {
    display: none;
  }
  @media (max-width: $screen-sm) {
    display: inline;
  }
}

.shortLabel {
  display: none;
  @media (max-width: 1600px) {
    display: none;
  }
  @media (max-width: $screen-xl) {
    display: inline;
  }
    @media (max-width: $screen-sm) {
        display: none;
    }
}

