@import '../../../styles/general/variables';

.dropdown {
    display: inline-block;
    position: relative;
    button, a {
        -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
    }
}

.dropdownContent {
    display: none;
    position: absolute;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    bottom: 125%;
    z-index: 90;
    width: 260px;
    min-width: 160px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border-radius: 4px;

    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);

    //arrow
    &:after {
        display: none;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -0.8em;
        margin-right: -0.2em;
        right: 50%;
        box-sizing: border-box;
        border: 0.4em solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    }
}

.dropdownContentLeft {
    right: auto;
    left: 0;
    transform: none;
    &:after {
        left: 10%;
        right: auto;
    }
}

.dropdownContentRight {
    right: 0;
    left: auto;
    transform: none;
    &:after {
        right: 15%;
        left: auto;
    }
}

.dropdownContentWithArrow {
    &:after {
        display: block;
    }
}

.dropdownToggle {
    display: block;
}

.active .dropdownContent {
    display: block;
}
